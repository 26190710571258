import React from "react"
import SEO from "components/globals/seo"
import Link from 'components/globals/link'

export default () => (
	<div className="page-404">
		<SEO title="404: Not found" />
		<h1>
			Page not found
		</h1>
		<p>
			We can't find anything at this address. Perhaps head to the <Link to='/'>home page</Link>, and try from there?
		</p>
	</div>
)